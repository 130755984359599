import { SvgIcon, SvgIconProps } from '@mui/material';

const ArticleIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.99268 0.134525L8.92211 0.134523H8.92207C7.56797 0.134488 6.45137 0.13446 5.56762 0.253277C4.63979 0.378021 3.82036 0.649989 3.16425 1.3061C2.50814 1.96221 2.23618 2.78163 2.11143 3.70946C1.99261 4.59321 1.99264 5.70981 1.99268 7.06392V7.06395L1.99268 7.13452V16.8655L1.99268 16.9361V16.9361C1.99264 18.2902 1.99261 19.4068 2.11143 20.2905C2.23618 21.2184 2.50814 22.0378 3.16425 22.6939C3.82036 23.35 4.63979 23.622 5.56762 23.7467C6.45137 23.8655 7.56799 23.8655 8.92211 23.8655H8.92214H8.99268H16.0073H16.0778H16.0778C17.432 23.8655 18.5486 23.8655 19.4323 23.7467C20.3602 23.622 21.1796 23.35 21.8357 22.6939C22.4918 22.0378 22.7638 21.2184 22.8885 20.2905C23.0073 19.4068 23.0073 18.2902 23.0073 16.936V16.936V16.8655V7.13452V7.06398V7.06396C23.0073 5.70984 23.0073 4.59322 22.8885 3.70946C22.7638 2.78163 22.4918 1.96221 21.8357 1.3061C21.1796 0.649989 20.3602 0.378021 19.4323 0.253277C18.5486 0.13446 17.432 0.134488 16.0779 0.134523H16.0778L16.0073 0.134525H8.99268ZM4.57847 2.72031C4.80104 2.49774 5.12806 2.33037 5.83411 2.23544C6.56893 2.13665 7.5502 2.13453 8.99268 2.13453H16.0073C17.4498 2.13453 18.431 2.13665 19.1658 2.23544C19.8719 2.33037 20.1989 2.49774 20.4215 2.72031C20.644 2.94288 20.8114 3.2699 20.9063 3.97596C21.0051 4.71078 21.0073 5.69204 21.0073 7.13452V16.8655C21.0073 18.308 21.0051 19.2892 20.9063 20.024C20.8114 20.7301 20.644 21.0571 20.4215 21.2797C20.1989 21.5023 19.8719 21.6696 19.1658 21.7646C18.431 21.8634 17.4498 21.8655 16.0073 21.8655H8.99268C7.5502 21.8655 6.56893 21.8634 5.83411 21.7646C5.12806 21.6696 4.80104 21.5023 4.57847 21.2797C4.35589 21.0571 4.18852 20.7301 4.0936 20.024C3.9948 19.2892 3.99268 18.308 3.99268 16.8655V7.13452C3.99268 5.69204 3.9948 4.71078 4.0936 3.97596C4.18852 3.2699 4.35589 2.94288 4.57847 2.72031Z"
                fill="currentColor"
            />
            <path d="M7.96924 7.51904H16.9151" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <path d="M7.96924 12H16.9151" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <path d="M7.96924 16.8127H13.2483" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        </SvgIcon>
    );
};

export default ArticleIcon;
