import { SvgIcon, SvgIconProps } from '@mui/material';

const MarkerIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path
                d="M22.7768 7.66437L16.6989 1.17505L3.97339 13.3624C4.73254 14.0588 4.89136 16.5913 3.97339 17.8891L7.17057 21.2446C8.5001 20.0417 10.2442 20.5477 11.4757 21.2446L22.7768 7.66437Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="transparent"
            />
            <path
                d="M2.36372 20.3213L0.22058 22.6053C0.114374 22.72 0.0424284 22.8656 0.013822 23.0239C-0.0147844 23.1821 0.00122994 23.3458 0.0598444 23.4944C0.117265 23.6433 0.214776 23.7708 0.340089 23.8608C0.465402 23.9508 0.612908 23.9993 0.764019 24.0001H3.51948C3.62022 24.0007 3.72007 23.9801 3.81333 23.9396C3.90658 23.899 3.9914 23.8391 4.06292 23.7635L4.82833 22.9478L2.36372 20.3213Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default MarkerIcon;
